<template>
  <div>
    <Menu />
    <div style="background-color: #e5720b; padding: 10px; position: fixed; bottom: 10px; right: 10px; z-index: 9999;">
        <a href="#form" style="color: #fff; text-decoration: none;">
            <strong style="color: #fff;">ENQUIRE NOW</strong>
        </a>
    </div>
    <section id="banner" class="my-5">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-12 bg-light bord-radius">
            <div class="banner-section">
              <center><img src="/images/cd-logo.svg" style="width: 200px;" class="pt-5"></center>
              <center><h4 class="py-3">Our Trusted Partners in Luxury Property Transactions</h4></center>
              <img src="/images/banners/currencies-banner.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="" class="mt-5">
      <div class="container p-0 switch-bg-lgrey bord-radius">
        <div class="row justify-content-center bord-radius">
          <div class="col-md-4 p-5">
            <img src="/images/currencies-1.jpg" class="img-fluid bord-radius">
          </div>
          <div class="col-md-8 p-5 text-start">
            <p class="mt-4"><strong>At Chas Everitt Luxury, we partner with the best in the business to offer you bespoke services that enhance your international property investments.</strong></p>
            <p class="mt-4">Currencies Direct has been a leader in foreign exchange since 1996, helping clients across the globe move their money securely and efficiently. As a UK-based company with a global presence, we specialise in providing tailored currency solutions that save you time, money, and stress.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="" class="mt-5">
      <div class="container mt-5 bg-dark text-light bord-radius">
        <div class="row p-3">
          <div class="col-md-12">
            <center><h4 class="text-light">Why Choose Currencies Direct?</h4></center>
          </div>
        </div>
      </div>
      <div class="container mt-5 switch-bg-lgrey bord-radius">
        <div class="row p-3">
          <div class="col-md-3">
              <p><strong>No Hidden Costs</strong></p>
              <p>Unlike traditional banks, Currencies Direct offers the best exchange rates with no hidden transfer fees or commissions.</p>
          </div>
          <div class="col-md-3">
              <p><strong>Expert Guidance</strong></p> 
              <p>Currencies Direct offers various services – from your own designated dealer to your rate watch facilities where you can determine when you book your rate – giving our clients the ability to maximise their money.</p>
          </div>
          <div class="col-md-3">
              <p><strong>Global Reach</strong></p> 
              <p>With over 430,000+ customers around the world being serviced by more than 550+ currency experts in over 25+ offices, across four continents, Currencies Direct boasts a 24/7 business facilitating international payments with our global banking partners.</p>
          </div>
          <div class="col-md-3">
              <p><strong>Navigating the Red Tape</strong></p> 
              <p>From assisting in setting up an intermediary account to collating and submitting the paperwork once we have obtained the relevant approval for the transaction, we handle the complexities from start to finish.</p>
          </div>
        </div>
      </div>
      <div class="container mt-5 bord-radius bg-dark text-light">
        <div class="row p-3">
          <div class="col-md-12 text-center">
            <h4 class="text-light">How It Works</h4>
            <p class="mt-4 text-light">Our process is simple and streamlined to ensure your money is transferred safely and efficiently.</p>
          </div>
        </div>
      </div>
      <div class="container mt-5 switch-bg-lgrey bord-radius">
        <div class="row p-3">
          <div class="col-md-3">
              <p><strong>1. Account Setup</strong></p>
              <p>We quickly open an intermediary account through our banking partners like Barclays in the UK and Investec in South Africa.</p>
          </div>
          <div class="col-md-3">
              <p><strong>2. Paperwork Handling:</strong></p>
              <p> We manage all necessary documentation, including exchange control approvals, to ensure a smooth transaction.</p>
          </div>
          <div class="col-md-3">
              <p><strong>3. Rate Monitoring</strong></p>
              <p>We keep an eye on exchange rates and contact you when it’s the best time to book a transfer.</p>
            </div>
          <div class="col-md-3">
              <p><strong>4. Fund Transfer</strong></p>
              <p>Once everything is in place, we send the funds where they need to go, securely and promptly.</p>
          </div>
        </div>
      </div>
      <a id="form"></a>
      <div class="container mt-5 bord-radius bg-dark text-light">
        <div class="row p-3 d-flex align-items-center">
          <div class="col-md-6 text-start">
            <h1 class="text-start">Experience stress-free currency transfers with Currencies Direct</h1>
          </div>
          <div class="col-md-6 text-end">
            <img src="/images/currencies-4.jpg" class="img-fluid bord-radius">
          </div>
        </div>
      </div>
    </section>
    <!-- form -->
    <section>
      <div class="container p-0 p-md-5">
        <div class="row justify-content-center g-0 switch-bg-lgrey bord-radius">
          <div class="col-12 px-sm-5 pt-3">
            <div class="banner-section lifestyle-block p-3 h-100">
              <center><h4 class="pb-3"><strong>Send an enquiry message</strong></h4></center>
              <form id="buyForm" class="formStyle" @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <input type="text" class="form-control" v-model="data.name" placeholder="NAME" id="name">
                      <span class="error-message">{{ data.errors.name }}</span>
                    </div>
                    <div class="mb-3">
                      <input type="tel" class="form-control" v-model="data.phone" placeholder="PHONE" id="phone">
                      <span class="error-message">{{ data.errors.phone }}</span>
                    </div>
                    <div class="mb-3">
                      <input type="email" class="form-control" v-model="data.email" placeholder="EMAIL" id="email">
                      <span class="error-message">{{ data.errors.email }}</span>
                    </div>
                    <div class="mb-3">
                      <textarea class="form-control" v-model="data.message" placeholder="WHAT ARE YOU LOOKING FOR?" id="message"></textarea>
                      <span class="error-message">{{ data.errors.message }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Please select the option that best describes your needs:</label>

                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="relocating" name="enquiry" value="Relocating Elegantly" required v-model="data.enquiry">
                        <label class="form-check-label" for="relocating">
                          <strong>Relocating Elegantly:</strong> Are you emigrating from South Africa and seeking a seamless way to transfer your wealth abroad?
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="investing" name="enquiry" value="Investing with Confidence" required v-model="data.enquiry">
                        <label class="form-check-label" for="investing">
                          <strong>Investing with Confidence:</strong> Are you acquiring property in South Africa and require a sophisticated, cost-effective solution to move your funds?
                        </label>
                      </div>

                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="rentals" name="enquiry" value="Managing International Rentals" required v-model="data.enquiry">
                        <label class="form-check-label" for="rentals">
                          <strong>Managing International Rentals:</strong> As an international tenant, do you need a hassle-free way to manage your rental payments in South Africa?
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="management" name="enquiry" value="Global Property Management" required v-model="data.enquiry">
                        <label class="form-check-label" for="management">
                          <strong>Global Property Management:</strong> As a landlord residing abroad, are you looking to repatriate your rental income with ease and efficiency?
                        </label>
                      </div>
                      <span class="error-enquiry">{{ data.errors.enquiry }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="col-md-12">
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.acceptTerms" id="acceptTerms">
                      <label class="form-check-label" for="acceptTerms">I have read and accept the <a target="_blank" href="https://www.chaseveritt.co.za/disclaimer-policy-website/" class="switch-blue">terms and conditions</a></label>
                    </div>
                    <div class="form-check mb-2">
                      <input type="checkbox" class="form-check-input" v-model="data.receiveMarketing" id="receiveMarketing" value="Yes">
                      <label class="form-check-label" for="receiveMarketing">I want to receive marketing material</label>
                    </div>
                  </div>
                  <div class="social-links switch-bg-dblue p-0 form-btn">
                    <button type="submit" class="btn switch-white" :disabled="!data.acceptTerms">SEND MESSAGE</button>
                  </div>
                  <div class="success-message pb-2" v-if="successMessage">{{ successMessage }}</div>
                  <div class="error-message pb-2" v-if="errorMessage">{{ errorMessage }}</div>
                </div>  
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import SeoLinks from '@/components/home/SeoLinks.vue'
import axios from 'axios'

export default {
  components: {
    Menu, Footer, SeoLinks
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        phone: "",
        message: "",
        enquiry: "ASSET MANAGEMENT QUERY",
        receiveMarketing: false,
        acceptTerms: false,
        errors: {
          name: '',
          email: '',
          phone: '',
          message: '',
          enquiry: '',
        },
      },
      successMessage: '',
      errorMessage: '',
    }
  },
  methods: {
    submitForm() {

      this.data.errors = {};
      this.successMessage = '';
      this.errorMessage = '';

      if (!this.data.name) {
        this.data.errors.name = "Name is required";
      }

      if (!this.data.email) {
        this.data.errors.email = "Email is required";
      }

      if (!this.data.phone) {
        this.data.errors.phone = "Phone is required";
      } else if (!/^\d+$/.test(this.data.phone)) {
        this.data.errors.phone = "Phone must contain only numeric characters";
      }

      if (!this.data.message) {
        this.data.errors.message = "Message is required";
      }

      if (!this.data.enquiry) {
        this.data.errors.enquiry = "Message is required";
      }

      if (Object.keys(this.data.errors).length === 0) {
        axios.post("https://stratitude.co.za/api/cd-form.php", {
          name: this.data.name,
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          enquiry: this.data.enquiry,
          receiveMarketing: this.data.receiveMarketing
        })
        .then(response => {
          this.successMessage = "Email sent successfully.";
          this.data.name = "";
          this.data.email = "";
          this.data.phone = "";
          this.data.message = "";
          this.data.enquiry = "";
          this.data.acceptTerms = false;
          this.data.receiveMarketing = false;
        })
        .catch(error => {
          this.errorMessage = "Email sending failed.";
        });
      }

    },
  },
}
</script>
<style scoped>
  .nav-link-vertical {
    background: none;
    border: none;
    color: #666;
    text-decoration: none;
  }
  .nav-link-vertical.active {
    color: #fff;
  }

</style>